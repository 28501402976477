import { DataTableStyle } from "./data-table-style";
import { InputStyle } from "./input-style";
import { Theme } from "./theme";
import { HeaderStyle } from "./header-style";

export class UserPreferences {
  theme: Theme = Theme.Default;
  dataTableStyle: DataTableStyle = DataTableStyle.Default;
  inputStyle: InputStyle = InputStyle.Default;
  headerStyle: HeaderStyle = HeaderStyle.Default;
}
