export const loginCypressMockUser = (b2c_client_id: string, tokens: { key: string; value: any }[] | undefined) => {
  let identityId = "";
  const activeAccount = `msal.${b2c_client_id}.active-account`;
  for (const token of tokens) {
    localStorage.setItem(token.key, token.value);
    if (token.key === activeAccount) {
      identityId = token.value.split("-b2c_1_signine2e")[0];
    }
  }

  localStorage.setItem(
    `${identityId}.id`,
    JSON.stringify({
      activeCompanyIndex: 0,
      activeClientIndex: 0,
      employees: [
        {
          id: "d203ffae-505f-4bbc-bb30-156f35c735be",
          companyId: "b07d7b2b-4c58-419b-96a0-8eb7c2b2c58b",
          companyName: "Cypress Test",
          identityId: identityId,
          warehouseId: "0c6e8a6f-efc1-4018-a965-e5ce69d7d7b1",
          defaultClientId: "4c64364c-168b-4d95-9558-6a5f9f3ae25b",
          firstName: "Cumulus",
          lastName: "E2e",
          Initials: "CE",
          email: "cumulus@multicase.no",
          clients: [
            {
              id: "4c64364c-168b-4d95-9558-6a5f9f3ae25b",
              companyId: "b07d7b2b-4c58-419b-96a0-8eb7c2b2c58b",
              name: "Test Client",
            },
          ],
          roles: ["admin"],
          employeeState: "Active",
          languageCode: "NO",
          preferences: {
            theme: "Default",
            dataTableStyle: "Default",
            inputStyle: "Default",
            headerStyle: "Default",
          },
        },
      ],
      cacheExpiration: new Date().getTime() + 50 * 60 * 1000,
    })
  );
};

export const logoutCypressMockUser = (b2c_client_id: string) => {
  const activeAccount = localStorage.getItem(`msal.${b2c_client_id}.active-account`);

  if (activeAccount) {
    const identityId = activeAccount.split("-b2c_1_signine2e")[0];

    localStorage.setItem(
      `${identityId}.id`,
      JSON.stringify({
        activeCompanyIndex: 0,
        activeClientIndex: 0,
        employees: [
          {
            id: "d203ffae-505f-4bbc-bb30-156f35c735be",
            companyId: "b07d7b2b-4c58-419b-96a0-8eb7c2b2c58b",
            companyName: "Cypress Test",
            identityId: identityId,
            defaultClientId: "4c64364c-168b-4d95-9558-6a5f9f3ae25b",
            firstName: "Cumulus",
            lastName: "E2e",
            email: "cumulus@multicase.no",
            clients: [
              {
                id: "4c64364c-168b-4d95-9558-6a5f9f3ae25b",
                companyId: "b07d7b2b-4c58-419b-96a0-8eb7c2b2c58b",
                name: "Test Client",
              },
            ],
            roles: ["admin"],
            employeeState: "Active",
            languageCode: "NO",
            preferences: {
              theme: "c-theme-default",
              dataTableStyle: "c-datatable-default",
              inputStyle: "c-input-default",
              headerStyle: "c-header-default",
            },
          },
        ],
        cacheExpiration: new Date().getTime() + 50 * 60 * 1000,
      })
    );
  }
};
